import { defineStore } from "pinia";
import Kortingscodes from "~/pages/importeren/kortingscodes.vue";

export const useCart = defineStore("cartStore", () => {
	const { getItems, getItemById, updateItem, createItems, deleteItems } = useDirectusItems();
	// const { $bus } = <any> useNuxtApp();
	const cartLoaded = ref(false);

	const user = useDirectusUser();
	const userId = ref("");
	if (!userId.value) {
		userId.value = getOrSetUserId();
	}

	const cart = ref({
		id: 0,
		producten: [] as any[],
		kortingscode: {},
	});
	const quantity = ref(0);
	const totalOfCart = ref(0);
	const getCartLength = ref(0);
	const totalTaxCart = ref(0);
	const subTotal = ref(0);
	const totalDiscount = ref(0);
	const totalStatiegeldQuantity = ref(0);
	const totalStatiegeldAmount = ref(0);
	const totalStatiegeld = ref(0);
	const heeftStatiegeld = ref(false);
	const loading = ref(true);

	watch(() => totalDiscount.value, () => {
			calculateTotalOfCart();
		}
	);

	const $reset = () => {
		userId.value = "";
		user.value = null;

		cart.value = {
			id: 0,
			producten: [],
			kortingscode: {},
		};
		quantity.value = 0;
		totalOfCart.value = 0;
		getCartLength.value = 0;
		totalTaxCart.value = 0;
		subTotal.value = 0;
		totalDiscount.value = 0;
		totalStatiegeldQuantity.value = 0;
		totalStatiegeldAmount.value = 0;
		totalStatiegeld.value = 0;
		heeftStatiegeld.value = false;

		if (!userId.value) {
			userId.value = getOrSetUserId();
		}
	};

	watch(() => cart.value.producten, (newVal: any, oldVal: any) => {
			// let total = 0;

			// // console.log('watcherNEWVAL', newVal)

			// newVal.forEach((item: any) => {
			// 	total += item.aantal;
			// })
			// quantity.value = total;

			// // console.log('watcherTOTAL', total)

			quantity.value = newVal.length ?? 0;
		}
	);

	function randomString(length: number) {
		let result = "";
		const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		const charactersLength = characters.length;
		for (let i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return result;
	}

	function getOrSetUserId() {
		const localStorageUserId = localStorage.getItem("newUserId") ?? null;
		if (localStorageUserId === null) {
			const newUserId = randomString(25);
			localStorage.setItem("newUserId", newUserId);
			return newUserId;
		} else {
			return localStorageUserId;
		}
	}

	function setUserId(newUserId: string) {
		localStorage.setItem("newUserId", newUserId);
		userId.value = newUserId;
	}

	const valideerDubbeleWinkelwagen = async (winkelwagenResponse: any) => {
		console.log('winkelwagenResponse', winkelwagenResponse);
  
		const klantWinkelWagen = winkelwagenResponse.find((winkelwagenItem: any) => {
			if (winkelwagenItem.klant !== null) {
				return winkelwagenItem;
			}
		});
		const anoniemeWinkelWagen = winkelwagenResponse.find((winkelwagenItem: any) => {
			if (winkelwagenItem.klant === null) {
				return winkelwagenItem;
			}
		});

		console.log(klantWinkelWagen, anoniemeWinkelWagen)

    // const newUserIdWinkelWagen

		// if (klantWinkelWagen && anoniemeWinkelWagen) {
		// 	await updateItem({
		// 		// update existing klantwinkelwagen because just selected products are leading
		// 		collection: "winkelwagens",
		// 		id: klantWinkelWagen.id,
		// 		item: {
		// 			nieuwe_klant_id: userId.value,
		// 			producten: anoniemeWinkelWagen.producten,
		// 		},
		// 	});
		// 	// delete the anoniemewinkelwagen
		// 	await deleteItems({
		// 		// delete anonieme winkelwagen
		// 		collection: "winkelwagens",
		// 		items: [anoniemeWinkelWagen.id],
		// 	});
		//
		// 	await getCartData();
		// }

		return null;
	};

	const getCartData = async () => {
		const user = useDirectusUser();
		if (!userId.value) {
			userId.value = getOrSetUserId();
		}

		if (!user.value) {
			return null;
		}

		// console.log("Getting cart data");
		const winkelwagenResponse = <any>await getItems({
			collection: "winkelwagens",
			params: {
				limit: -1,
				filter: { _or: [{ nieuwe_klant_id: { _eq: userId.value } }, { klant: { _eq: user.value?.id ? user.value.id : null } }, { ingelogde_klant: { _eq: user.value?.id ? user.value.id : null } }] },
				fields: ["id", "producten.aantal", "producten.totaalprijs_product", "producten.prijs_product", "producten.producten_id.*", "klant", "ingelogde_klant", "nieuwe_klant_id", "kortingscode.*"],
			},
        });
        
		
		// console.log('winkelwagenResponse', winkelwagenResponse);
		// console.log('user.value', user.value);
		

		if (winkelwagenResponse.length > 0) {
			if (winkelwagenResponse.length > 1 && user.value) {
				await valideerDubbeleWinkelwagen(winkelwagenResponse);
				return null;
			} else {
				let winkelwagen = winkelwagenResponse[0];
				if (user.value) {
					await updateItem({
						collection: "winkelwagens",
						id: winkelwagen.id,
						item: {
							klant: user.value.id,
						},
					});

					if (winkelwagen.nieuwe_klant_id) {
						setUserId(winkelwagen.nieuwe_klant_id);
					}
				}

				cart.value = winkelwagen;
				// calculateTotalOfCart();
				return winkelwagen;
			}
		} else {
			return null;
		}
	};

	const addToCart = async (id: number, aantal: any) => {
		let winkelwagen = cart.value.producten.length > 0 ? cart.value : <any>await getCartData();
		const { login, createUser } = useDirectusAuth();

		loading.value = true
		let userItem = {};
		if (!user.value && !winkelwagen) {
			let randomString = Math.random().toString(36).substring(2, 15);
			await createUser({
				email: "temp_" + randomString + "@example.com",
				datecreated: new Date().toLocaleDateString(),
				password: "OOI8b5vzfFy3men5OAOv2OP6RrjoW6z8w7o",
				role: "a2a6ea38-1641-4d4d-83aa-bbf83034b43a",
				tempUser: true,
			}).then(async (response: any) => {
				userItem = response.data;
				await login({
					firstname: "temp_" + randomString,
					lastname: "temp_" + randomString,
					email: "temp_" + randomString + "@example.com",
					password: "OOI8b5vzfFy3men5OAOv2OP6RrjoW6z8w7o",
				});
				// console.log("Creating new user", response);
			});
		} else {
			userItem = user.value;
			// winkelwagen = cart.value.producten.length > 0 ? cart.value : <any>await getCartData()
		}

		let newWinkelwagen: any = {};

		if (winkelwagen === null) {
			await createItems({
				collection: "winkelwagens",
				items: [{
					//@ts-ignore
					klant: userItem.id,
					//@ts-ignore
					ingelogde_klant: userItem.id,
					nieuwe_klant_id: userId.value,
				}],
			}).then(async (response: any) => {
				// console.log("Creating new cart", response);
				// loading.value = false
				newWinkelwagen = response[0];
			});
			// console.log("Creating new cart");
			// winkelwagen = winkelwagen[0];
			// winkelwagen = cart.value.producten.length > 0 ? cart.value : <any>await getCartData()
		} else {
			// loading.value = false
			newWinkelwagen = winkelwagen;
		}

		// console.log('newWinkelwagen', newWinkelwagen);
		

		const winkelwagenId = newWinkelwagen.id ?? null;
		// console.log('newWinkelwagen: ', newWinkelwagen)

		if (newWinkelwagen.producten.length > 0) {
			const productenWinkelwagen = <any>await getItems({
				collection: "winkelwagens_producten",
				params: {
					filter: {
						_and: [{ winkelwagens_id: { _eq: winkelwagenId } }, { producten_id: { _eq: id } }],
					},
					fields: ["id", "producten_id", "aantal"],
				},
			});

			if (productenWinkelwagen.length > 0) {
				const totalAantal = productenWinkelwagen[0].aantal + parseFloat(aantal);
				await updateProductWinkelwagen(productenWinkelwagen[0], totalAantal);
				getCartData().then(() => calculateTotalOfCart());
			} else {
				await createItems({
					collection: "winkelwagens_producten",
					items: [
						{
							winkelwagens_id: winkelwagenId,
							producten_id: id,
							aantal: parseFloat(aantal),
						},
					],
                }).then(async (createResponse) => {
                    if (createResponse && createResponse.length > 0) {
                        await updateProductWinkelwagen(createResponse[0], parseFloat(aantal));
                    }
				});

				getCartData().then(() => calculateTotalOfCart());
			}
		} else {
			await createItems({
				collection: "winkelwagens_producten",
				items: [
					{
						winkelwagens_id: winkelwagenId,
						producten_id: id,
						aantal: parseFloat(aantal),
					},
				],
			}).then(async (res) => {
				// console.log('res', res)
				await updateProductWinkelwagen(res[0], parseFloat(aantal));
			});

			getCartData().then(() => calculateTotalOfCart());
		}
	};

	const removeFromCart = async (id: number) => {
		const winkelwagen = cart.value.producten.length > 0 ? cart.value : <any>await getCartData();
		const{$bus} = useNuxtApp()

		if (winkelwagen === null) {
			return;
		}

		if (winkelwagen.producten.length > 0) {
			const productenWinkelwagen = <any>await getItems({
				collection: "winkelwagens_producten",
				params: {
					filter: {
						_and: [{ winkelwagens_id: { _eq: winkelwagen.id } }, { producten_id: { _eq: id } }],
					},
					fields: ["id", "producten_id", "aantal"],
				},
			});

			await deleteItems({
				collection: "winkelwagens_producten",
				items: [productenWinkelwagen[0].id],
			});

			cart.value.producten = cart.value.producten.filter((item: any) => item.producten_id.id !== id);

			getCartData();
			// @ts-ignore
			$bus.$emit("cartUpdated");
		}
		calculateTotalOfCart();
	};

	const productIsInCart = (id: number) => {
		if (cart.value.producten.length === 0) return false;
		return cart.value.producten.find((item: any) => item.producten_id.id === id);
	};

	const changeCartQuantity = async (id: number, aantal: number) => {
		const winkelwagen = cart.value.producten.length > 0 ? cart.value : <any>await getCartData();

		if (winkelwagen === null) {
			return;
		}

		if (winkelwagen.producten.length > 0) {
			const productenWinkelwagen = <any>await getItems({
				collection: "winkelwagens_producten",
				params: {
					filter: {
						_and: [{ winkelwagens_id: { _eq: winkelwagen.id } }, { producten_id: { _eq: id } }],
					},
					fields: ["id", "producten_id", "aantal"],
				},
			});

			if (productenWinkelwagen[0].aantal + aantal <= 0) {
				await removeFromCart(id)
				return;
			}


			const totalAantal = productenWinkelwagen[0].aantal + aantal
			await updateProductWinkelwagen(productenWinkelwagen[0], totalAantal);

			cart.value.producten = cart.value.producten.map((item: any) => {
				if (item.producten_id.id === id) {
					item.aantal += aantal;
				}
				return item;
			});
		}
		await calculateTotalOfCart();
	};

	async function setCartQuantity(id: number, aantal: number) {
		// const winkelwagen = cart.value.length > 0 ? cart.value : <any>await getCartData()

		if (cart.value === null) {
			return;
		}

		if (cart.value.producten.length > 0) {
			const productenWinkelwagen = <any>await getItems({
				collection: "winkelwagens_producten",
				params: {
					filter: {
						_and: [{ winkelwagens_id: { _eq: cart.value.id } }, { producten_id: { _eq: id } }],
					},
					fields: ["id", "producten_id", "aantal"],
				},
			});

			if (productenWinkelwagen[0].aantal + aantal <= 0) {
				await removeFromCart(id);
				return;
			}

			await updateProductWinkelwagen(productenWinkelwagen[0], aantal);

			cart.value.producten = cart.value.producten.map((item: any) => {
				if (item.producten_id.id === id) {
					item.aantal = aantal;
				}
				return item;
			});
		}
		calculateTotalOfCart();
	}

	async function calculateTotalOfCart() {
		// console.log("Calculating total of cart");
		// let winkelwagen = cart.value.length > 0 ? cart.value : <any>await getCartData()

		if (cart.value.producten.length === 0) {
			totalOfCart.value = 0;
			totalTaxCart.value = 0;
			subTotal.value = 0;
			totalDiscount.value = 0;
			getCartLength.value = 0;
			totalStatiegeldQuantity.value = 0;
			totalStatiegeldAmount.value = 0;
			return;
		}

		checkStatiegeldProducts(cart.value.producten);

		let total = 0;
		let totalTax = 0;
		let subTotalValue = 0;
		let getCartLengthValue = 0;

		total += totalStatiegeldAmount.value;
		totalTax += (totalStatiegeldAmount.value / 100) * 9;

		cart.value.producten.forEach((item: any) => {
			// console.log({item})
			total += parseFloat(item.totaalprijs_product);
			totalTax += (item.totaalprijs_product / 100) * parseInt(item.producten_id.btw);
			subTotalValue += parseFloat(item.totaalprijs_product);
			getCartLengthValue += parseFloat(item.aantal);
		});

		if (totalDiscount.value > 0) {
			total -= totalDiscount.value;
			totalTax -= (totalDiscount.value / 100) * 9;
		}

		totalOfCart.value = total;
		totalTaxCart.value = totalTax;
		subTotal.value = subTotalValue;
		getCartLength.value = getCartLengthValue;
	}

	async function removeCart() {
		const { deleteItems } = useDirectusItems();
		await deleteItems({
			collection: "winkelwagens",
			items: [cart.value.id],
		});
		const winkelwagens_producten = <any>[];
		const getWinkelwagensProducten = await getItems({
			collection: "winkelwagens_producten",
			params: {
				filter: {
					winkelwagens_id: { _eq: cart.value.id },
				},
			},
		});
		getWinkelwagensProducten.forEach((item: any) => {
			winkelwagens_producten.push(item.id);
		});
		await deleteItems({
			collection: "winkelwagens_producten",
			items: winkelwagens_producten,
		});
	}

	async function updateCartKortingscode(kortingscodeId: number) {
		// console.log("Updating kortingscode winkelwagen", kortingscodeId);

		return new Promise(async (resolve, reject) => {
			const winkelwagen = cart.value.producten.length > 0 ? cart.value : <any>await getCartData();
			// console.log("Updating kortingscode winkelwagen", winkelwagen);

			if (winkelwagen === null) {
				return;
			}

			try {
				await updateItem({
					collection: "winkelwagens",
					id: winkelwagen.id,
					item: {
						kortingscode: kortingscodeId,
					},
				});

				resolve("");
			} catch (error) {
				// console.log("Kortingscode not updated");
				reject(error);
			}
		});
	}

	async function onLogin() {
		return new Promise((resolve): any => {
			const currentUser = useDirectusUser();
			if (currentUser.value) {
				user.value = currentUser.value;
			}
			getCartData().then(() => calculateTotalOfCart());

			resolve(""); // return user data
		});
	}

	async function onLogout() {
		return new Promise(async (resolve): Promise<any> => {
			try {
				// remove local storage newUserId
				localStorage.removeItem("newUserId");
			} catch (error) {
				// console.log("User id not removed from local storage");
			}

			// reset cart
			$reset();

			setTimeout(() => {
				getCartData().then(() => calculateTotalOfCart());
				resolve("");
			}, 200);
		});
	}

    async function updateCartIdForNewUser(id: number) {
        const user = useDirectusUser();

		console.log('updateCartIdForNewUser', id, user)
		setTimeout(() => {
			updateItem({
				collection: "winkelwagens",
				id: id,
				item: {
					ingelogde_klant: user.value.id,
				},
			});
		},200)

	}

	function setStatiegeld(aantal: number, override: boolean = false) {
		if (override) {
			totalStatiegeldQuantity.value = aantal;
		} else {
			totalStatiegeldQuantity.value += aantal;
		}

		totalStatiegeldAmount.value = totalStatiegeldQuantity.value * -5;

		if (heeftStatiegeld.value === true) {
			totalStatiegeldAmount.value += 5;
		}

		calculateTotalOfCart();
	}

	function checkStatiegeldProducts(producten: any) {
		let statiegeld = false;
		producten.forEach((product: any) => {
			if (statiegeld) return;
			statiegeld = product.producten_id.statiegeld !== false;
		});

		heeftStatiegeld.value = statiegeld;
		if (statiegeld && totalStatiegeldQuantity.value === 0) {
			totalStatiegeldAmount.value = 5;
		} else if (!statiegeld && totalStatiegeldQuantity.value === 0) {
			totalStatiegeldAmount.value = 0;
		}
	}

	async function getProductPriceWithAmount(winkelwagen_product: any, totalAantal:any) {
		// console.log('winkelwagen_product', winkelwagen_product, totalAantal)
		const winkelwagenProduct = <any> await getItemById({
			collection: "producten",
			id: winkelwagen_product.producten_id,
		})

		if(winkelwagenProduct){
			if (winkelwagenProduct.staffelprijzen !== null) {
				const correctStaffel = winkelwagenProduct.staffelprijzen.find((staffel: any) => {
					if (totalAantal >= staffel.vanaf_aantal && totalAantal <= staffel.tot_aantal) {
						return staffel;
					} else if (totalAantal >= staffel.vanaf_aantal && staffel.tot_aantal === null) {
						return staffel;
					}
				})

				if (correctStaffel) {
					let totalPrijs = 0
					totalPrijs += parseFloat(totalAantal) * parseFloat(correctStaffel.prijs);
					return {totalPrijs: totalPrijs, prijsPerStuk: parseFloat(correctStaffel.prijs)};
				} else {
					return {totalPrijs: (parseFloat(totalAantal) * parseFloat(winkelwagenProduct.prijs)), prijsPerStuk: parseFloat(winkelwagenProduct.prijs)};
				}
			} else {
				return {totalPrijs: (parseFloat(totalAantal) * parseFloat(winkelwagenProduct.prijs)), prijsPerStuk: parseFloat(winkelwagenProduct.prijs)};
			}
		}
	}


	async function updateProductWinkelwagen(winkelwagen_product: any, totalAantal:any) {
    if(!winkelwagen_product) {
      getCartData().then(() => calculateTotalOfCart());
      return;
    }
		// console.log('winkelwagen_product', winkelwagen_product)

		const prijzen = <any> await getProductPriceWithAmount(winkelwagen_product, totalAantal);
		// console.log('prijzen', prijzen)
		await updateItem({
			collection: "winkelwagens_producten",
			id: winkelwagen_product.id,
			item: {
				aantal: totalAantal,
				totaalprijs_product: prijzen.totalPrijs,
				prijs_product: prijzen.prijsPerStuk,
			},
		});

		getCartData().then(() => calculateTotalOfCart());
    }

	getCartData().then(() => calculateTotalOfCart()); // set initial cart data

	return {
		loading,
		cart,
		quantity,
		totalOfCart,
		getCartLength,
		totalTaxCart,
		subTotal,
		totalDiscount,
		totalStatiegeldQuantity,
		totalStatiegeldAmount,
		totalStatiegeld,
		heeftStatiegeld,

		getCartData,
		addToCart,
		removeFromCart,
		productIsInCart,
		changeCartQuantity,
		updateCartKortingscode,
		setCartQuantity,
		setStatiegeld,
		removeCart,

		onLogin,
		onLogout,
		updateCartIdForNewUser,
	};
});
